var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"text-align-left"},[_c('h2',[_vm._v("Atelier Quiz de l’Anthropocène")]),(_vm.$i18n.locale !== 'fr')?_c('section',{staticClass:"alert alert-warning",attrs:{"role":"alert"}},[_vm._v(" 🌐Page not yet translated ")]):_vm._e(),_vm._m(0),_c('p',[_vm._v(" Climat, biodiversité, épuisement des ressources, transformation des espaces, pandémies, montées des tensions… Nous sommes entrés dans l'anthropocène, une ère où l'humain est devenu la plus grande force qui façonne le monde. ")]),_vm._m(1),_c('p',[_vm._v(" C’est à ces questions vertigineuses que l’atelier Quiz de l’Anthropocène vous convie, de façon ludique, collective et pédagogique. ")]),_c('p',[_vm._v(" A l'occasion de la Biennale des Villes en Transition, proposée en ligne par la ville de Grenoble en Avril 2021, nous avons pu valider le principe de cet atelier, qui a été apprécié par les participants ! ")]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('i',[_vm._v("Le quiz de l'anthropocène se décline désormais en atelier interactif !")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Comment développer sa lucidité vis à vis des dérèglements en cours ou à venir ?")]),_c('li',[_vm._v("Où trouver des informations fiables sur l'état de notre monde et des projections sérieuses sur les futurs possibles ?")]),_c('li',[_vm._v("Enfin, quels comportements et actions adopter pour préparer au mieux notre avenir et celui des générations suivantes ?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Jauge")]),_vm._v(" : 15 participants par session ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Durée")]),_vm._v(" : 2 heures ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Format")]),_vm._v(" : présentiel / distanciel, sous la houlette d’un animateur ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 📅 "),_c('i',[_vm._v("Prochaines dates à définir")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Ca vous intéresse ? Pour en savoir plus, écrivez-nous à "),_c('a',{attrs:{"href":"mailto:contact@quizanthropocene.fr"}},[_vm._v("contact@quizanthropocene.fr")])])
}]

export { render, staticRenderFns }