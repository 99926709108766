var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"text-align-left"},[_vm._m(0),(_vm.$i18n.locale !== 'fr')?_c('section',{staticClass:"alert alert-warning",attrs:{"role":"alert"}},[_vm._v(" 🌐Page not yet translated ")]):_vm._e(),_c('p',[_vm._v(" Le quiz de l'anthropocène se décline désormais en atelier interactif ! Climat, biodiversité, épuisement des ressources, transformation des espaces, pandémies, montées des tensions… Nous sommes entrés dans l'anthropocène, une ère où l'humain est devenu la plus grande force qui façonne le monde. ")]),_vm._m(1),_c('p',[_vm._v(" C’est à ces questions vertigineuses que l’atelier Quiz de l’Anthropocène vous convie, de façon ludique, collective et pédagogique. ")]),_c('p',[_vm._v(" A l'occasion de la Biennale des Villes en Transition, proposée en ligne par la ville de Grenoble du 1er au 4 avril 2021, 2 sessions vous sont proposées : ")]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('img',{staticClass:"img-fluid",attrs:{"src":"https://quiz-anthropocene.s3.fr-par.scw.cloud/app/2021_biennale_villes_transition_logo.jpg","alt":"Logo Biennale des Villes en Transition"}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v("Atelier pour la Biennale des Villes en Transition "),_c('small',[_vm._v("(1-4 avril 2021)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Comment développer sa lucidité vis à vis des dérèglements en cours ou à venir ?")]),_c('li',[_vm._v("Où trouver des informations fiables sur l'état de notre monde et des projections sérieuses sur les futurs possibles ?")]),_c('li',[_vm._v("Enfin, quels comportements et actions adopter pour préparer au mieux notre avenir et celui des générations suivantes ?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Jeudi 1er avril, 16h à 19h")]),_c('li',[_vm._v("Samedi 3 avril, 16h30 à 19h30")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Programme complet de la Biennale")]),_vm._v(" : "),_c('a',{attrs:{"href":"https://www.grenoble.fr/628-biennale-des-villes-en-transition-2021-grenoble.htm","target":"_blank"}},[_vm._v("https://www.grenoble.fr/628-biennale-des-villes-en-transition-2021-grenoble.htm")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Inscriptions")]),_vm._v(" : "),_c('a',{attrs:{"href":"mailto:contact@quizanthropocene.fr"}},[_vm._v("contact@quizanthropocene.fr")]),_vm._v(" (préciser pour quelle session vous souhaitez vous inscrire). ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Jauge")]),_vm._v(" : 15 participants max par session. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_c('a',{attrs:{"href":"https://quiz-anthropocene.s3.fr-par.scw.cloud/app/2021_biennale_villes_transition_affiche.pdf","target":"_blank"}},[_vm._v("Flyer (pdf, 1MB)")])])])
}]

export { render, staticRenderFns }